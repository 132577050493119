import React, { Component } from 'react';
import Layout from '../components/layout';

import './team.scss';

class TeamPage extends Component {
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('scrollTo');
        if (myParam) {
            let el = document.getElementById(myParam);
            el.scrollIntoView();
        }
    }

    render() {
        return (
            <Layout>
                <div className="team_wrapper" id="stereoscopic_team">
                    <h1>Government of Children Team</h1>
                    <p> Directed by Ioana Mischie </p>
                    <p> Produced by Storyscapes and Studioset</p>
                    <p>
                        <i>
                            In loving memory of my father, the most idealistic civic imagination practitioner ever met
                        </i>
                    </p>
                    <hr />
                    <h2>Young Leaders</h2>
                    <ul>
                        <li>Agheniței Marisa-Ada</li>
                        <li>Alexe Andrei-Claudiu</li>
                        <li>Alexe Mario-Aurelian</li>
                        <li>Andreea Gheorghe</li>
                        <li>Anghel Marinescu Filip</li>
                        <li>Aparaloaie Maya</li>
                        <li>Balaban Gabriel-Alexandru</li>
                        <li>Balaban Mihai-Radu</li>
                        <li>Bărbulescu Catinca</li>
                        <li>Bărbulescu Vlaicu</li>
                        <li>Berariu Radu</li>
                        <li>Berceanu Ivona</li>
                        <li>Berceanu Sașa</li>
                        <li>Bernovici Radu</li>
                        <li>Bianca Ochisor Myrto</li>
                        <li>Brașoveanu Daniel-Dimitrie</li>
                        <li>Buia Maria-Martha</li>
                        <li>Burcea Clara-Ioana</li>
                        <li>Caramitru Patrick</li>
                        <li>Chiritescu Vlad Teodor</li>
                        <li>Ciorbă Dragoș</li>
                        <li>Ciurilă Carmen-Ana-Maria</li>
                        <li>Ciutacu Constantin</li>
                        <li>Cocoș Lorena-Ioana</li>
                        <li>Coman Alexandra</li>
                        <li>Coman Ioana</li>
                        <li>Coman Maria</li>
                        <li>Cristescu Zamora Agatha-Mia</li>
                        <li>Dădălău Adela</li>
                        <li>Daros Carla</li>
                        <li>Dendea Anamaria</li>
                        <li>Deneș Andreea-Ondine</li>
                        <li>Dicu Ana</li>
                        <li>Dima Amza</li>
                        <li>Drăghici Larisa</li>
                        <li>Drăghici Vera</li>
                        <li>Dumitrache Cosmina</li>
                        <li>Dumitrescu Amalia-Elena</li>
                        <li>Dumitrescu Ioana</li>
                        <li>Dumitrescu Ioan-Valeriu</li>
                        <li>Dumitrescu Maria</li>
                        <li>Dumitrescu Sabrina-Maria</li>
                        <li>Dumitru Tudor</li>
                        <li>Focșa Kevin-Nicolas</li>
                        <li>Frazei Eli </li>
                        <li>Gâdici Mihnea</li>
                        <li>Ghela Tomas-Otto</li>
                        <li>Gheorghe David</li>
                        <li>Gheorghe Nicolae</li>
                        <li>Gheorghian Adina-Ioana</li>
                        <li>Ghiba Daria-Elena</li>
                        <li>Grozea Nathalia-Maria</li>
                        <li>Hadâmbu Alex</li>
                        <li>Hagiescu Anastasia-Teodora</li>
                        <li>Hagiescu Ioan-Alexandru</li>
                        <li>Haiduc Horia-Ioan</li>
                        <li>Haiduc Petru</li>
                        <li>Huțu Matei-Florin</li>
                        <li>Huzum Ema-Gabriela</li>
                        <li>Ionescu Erica</li>
                        <li>Ionescu Ștefan</li>
                        <li>Iordache Maia</li>
                        <li>Kraus Katarine-Sandra</li>
                        <li>Leocovici Ilinca</li>
                        <li>Leu Cătălin-Ștefan</li>
                        <li>Leu Răzvan-Andrei</li>
                        <li>Lixandru Antonio-Adrian</li>
                        <li>Loloiu Eva</li>
                        <li>Macarie Irina</li>
                        <li>Macarie Vlad</li>
                        <li>Marcan Evelyne-Ilinca</li>
                        <li>Marcan Julia-Maria</li>
                        <li>Margine Ștefania-Maria</li>
                        <li>Meilman Avi</li>
                        <li>Mihale Matei-Florin</li>
                        <li>Milicescu Răzvan-Cristian</li>
                        <li>Mischie Theodor Gabriel</li>
                        <li>Mitarcă Ștefan-Alexandru</li>
                        <li>Mitu Teodora-Maria</li>
                        <li>Moldoveanu Andra-Issabela</li>
                        <li>Moldoveanu Razvan-Cristian</li>
                        <li>Mustăreața Iacob-Nicolas</li>
                        <li>Mustăreață Sophia-Giselle</li>
                        <li>Neacșu Darius</li>
                        <li>Nechifor Ioana-Denisa</li>
                        <li>Nicolaisa Ioana</li>
                        <li>Paicu Cristian-Andrei</li>
                        <li>Petre George</li>
                        <li>Petre Maria-Alexandra</li>
                        <li>Polizu Matei</li>
                        <li>Radu Maria-Alexandra</li>
                        <li>Răduță Alexanra</li>
                        <li>Raiciu Maria</li>
                        <li>Rudolf Maia-Georgia</li>
                        <li>Rusu Tiberiu</li>
                        <li>Samoilă Denisse</li>
                        <li>Samoilă Theodor</li>
                        <li>Șerban Natalia-Elena</li>
                        <li>Smaranda Ana-Daria</li>
                        <li>Soare Florentina</li>
                        <li> Spînu Rebecca-Anne Marie</li>
                        <li>Staicu Robert</li>
                        <li>Stanciu Denisa</li>
                        <li>Stângă Alina-Gabriela</li>
                        <li>Stuparu Mara-Alexandra</li>
                        <li>Toma Bogdan-Andrei</li>
                        <li>Trandafir David-Stefan</li>
                        <li>Vijulan Rareș-Cosmin</li>
                        <li>Vlad Adriana-Elena-Gabriela</li>
                        <li>Vlad Irena</li>
                        <li>Voicu Gabriela</li>
                        <li>Voicu Mihaela</li>
                        <li>Voivozeanu Miruna</li>
                        <li>Zaraza Radu Maria</li>
                    </ul>
                    <hr />
                    <h2> Film Crew </h2>
                    <ul>
                        <li>Directed by Ioana Mischie</li>
                        <li>Transmedia Concept by Ioana Mischie</li>
                        <li>Director of Photography 3D - Zsolt Magyari</li>
                        <li>Director of Photography 2D - Radu Voinea</li>
                        <li>Set Design by Retro Future </li>
                    </ul>
                    <hr />
                    <h2> Produced by</h2>
                    Storyscapes and Studioset
                    <ul>
                        <li>Transmedia Producer – Ioana Mischie (Storyscapes)</li>
                        <li>National Producer – Sorin Baican (Studioset)</li>
                        <li>Creative Producer – Ioana Țurcan (Studioset)</li>
                    </ul>
                    <hr />
                    <h2>Production Department</h2>
                    <ul>
                        <li>Production Manager – Ioana Țurcan (Studioset)</li>
                        <li>Executive Producer – Adrian Smarandache</li>
                        <li>Production Assistants - Maria Lincu, Cristine Popovici, Ioana Diaconu</li>
                    </ul>
                    <hr />
                    <h2>Post Production</h2>
                    <ul>
                        <li>Editors - Mălin Stana, Delia Oniga, Bogdan Jugureanu</li>
                        <li>Studioset post-production coordinator - Anamaria Păun</li>
                        <li>CINETic post-production facilitator - Tănase Dumitru</li>
                    </ul>
                    <hr />
                    <h2>Set Design Department</h2>
                    <ul>
                        <li>Set Design by Retro Future (Anca Miron, Sonia Constantinescu)</li>
                        <li>Costume Design by Retro Future (Anca Miron, Sonia Constantinescu)</li>
                        <li>Set Design Assistants – Mădălina Anton, Alexandra Sucioaia, Ana Popa</li>
                        <li>Wardrobe - Maria Ciucanu</li>
                        <li>Props – Găman Ștefan</li>
                    </ul>
                    <hr />
                    <h2>Camera Department</h2>
                    <ul>
                        <li>Stereographer – Zsolt Magyari</li>
                        <li>Focus Puller – Iura Buștiuc, Ilie George, Andrei Rădulescu, Radu Bondoc</li>
                        <li>1st AC – Adrian Bârsan</li>
                        <li>Key Gaffer – Spetcu Valentin</li>
                    </ul>
                    <hr />
                    <h2>Sound Department</h2>
                    <ul>
                        <li>Sound Recordist – Tiberiu Ștefănescu</li>
                        <li>Sound Recordist – Andrei Stoian</li>
                    </ul>
                    <hr />
                    <h2>Sound Design and Music</h2>
                    <ul>
                        <li>Cristian Vieriu</li>
                    </ul>
                    <hr />
                    <h2>Sound Mix and Intro Music</h2>
                    <ul>
                        <li>Mihai Gheorghiu</li>
                    </ul>
                    <hr />
                    <h2>Color Grading </h2>
                    <ul>
                        <li>Lucian Iordan</li>
                    </ul>
                    <hr />
                    <h2>DIT</h2>
                    <ul>
                        <li>Platon Alexandru</li>
                        <li>Laurentiu Mihai</li>
                        <li>Roxana Sabău</li>
                        <li>Adrian Grigorescu</li>
                    </ul>
                    <hr />
                    <h2>Subtitling</h2>
                    <ul>
                        <li>Captioning – Miruna Baicu</li>
                        <li>Translation – kalimera.ro</li>
                    </ul>
                    <hr />
                    <h2>Set Photographers</h2>
                    <ul>
                        <li>Dragoș Mălăescu </li>
                        <li>Teodora Roșu</li>
                        <li>Alexandra Blejan</li>
                    </ul>
                    <hr />
                    <h2>Transport Department</h2>
                    <ul>
                        <li>Drivers – Gigi Vlad</li>
                    </ul>
                    <hr />
                    <h2>Catering Department</h2>
                    <ul>
                        <li>Catering Supplier – Restaurant Burebista</li>
                        <li>Catering Assistant - Maria Lincu</li>
                    </ul>
                    <hr />
                    <h2>Distribuție</h2>
                    <ul>
                        <li>Coordonator distribuție Ana Maria Pîrvan</li>
                        <li>Asistenți distribuție Ana Maria Pîrvan, Maria Lincu</li>
                    </ul>
                    <hr />
                    <h2>National Partners</h2>
                    <h3>CINETic</h3>
                    <ul>
                        <li>Alexandru Berceanu - technological grant facilitator</li>
                        <li>Conf. Univ. Dr. Nicolae Mandea - academic facilitator</li>
                        <li>Tănase Dumitru - technical facilitator</li>
                        <li>Geo Nița - technical facilitator</li>
                        <li>Alexandra Blejan - social media facilitator</li>
                        <li>Cip Făcăeru - extensions consultant</li>
                    </ul>
                    <h3>OUTER</h3>
                    <ul>
                        <li>Andreea Vasiliu</li>
                    </ul>
                    <h3>ELEMENTARY SCHOOLS</h3>
                    <ul>
                        <li>Școala de joacă, Piscu - Oana Popa</li>
                        <li>Hero after School, Clinceni - Monica Tănase</li>
                    </ul>
                    <h3>GREEN MIND STUDIO</h3>
                    <ul>
                        <li>Ștefan Anger</li>
                        <li>Manuel Sava</li>
                    </ul>
                    <h3>CREIONETICA</h3>
                    <ul>
                        <li>Andrei and Cristiana Bucureci</li>
                    </ul>
                    <hr />
                    <h2>Special Thanks</h2>
                    <ul>
                        <li>Platon Alexandru</li>
                        <li>Iulia Băcanu</li>
                        <li>Cosmin Gogu</li>
                        <li>Dan Sibișan</li>
                        <li>Marcel Ursachi</li>
                        <li>Adrian Șofran</li>
                        <li>Restaurant Burebista</li>
                    </ul>
                    <hr />
                    <h2>Infinite Thanks</h2>
                    <ul>
                        <li>Sorin Baican</li>
                        <li>Prof. Univ. Dr. Nicolae Mischie</li>
                        <li>Dr. Sanda Mischie</li>
                        <li>Dr. Alexandru Mischie</li>
                        <li>Manita </li>
                        <li>Nana </li>
                        <li>Fulbright Commission RO - U.S.</li>
                        <li>USC - School of Cinematic Arts (Civic Imagination & Worldbuilding Lab)</li>
                        <li>Professor Henry Jenkins</li>
                        <li>Professor Ann Cringler </li>
                        <li>Professor Sangita Shresthova</li>
                        <li>Professor Alex McDowell</li>
                        <li>Prof. Univ. Dr. Manuela Cernat</li>
                        <li>Prof. Univ. Dr. Nicolae Mandea</li>
                        <li>David Lopez</li>
                        <li>Artemis USC</li>
                    </ul>
                    <hr />
                    <hr />
                    <h2 id="website-team">Website Team</h2>
                    <ul>
                        <li>Transmedia Director - Ioana Mischie</li>
                        <li>Web Developer - Dragoș Silion</li>
                        <li>Web Designer - Bogdan Topirceanu</li>
                        <li>Web Designer - Teodora Gavrilă </li>
                        <li>Website facilitator - Ioana Țurcan</li>
                        <li>Producers - Storyscapes, Studioset</li>
                    </ul>
                    <hr />
                    <hr />
                    <h2 id="vr-team">VR Team</h2>
                    <ul>
                        <li>VR director - Ioana Mischie </li>
                        <li>VR consultants - Cip Făcăeru, Tudor Gheorghe </li>
                        <li>3D modelling - Emil Drăgan, Dragomir Cristian </li>
                    </ul>
                    <hr />
                    <hr />
                    <h2 id="biographies">
                        Echipa proiectului Guvernul Copiilor 3D / Government of Children 3D (biografii selective)
                    </h2>
                    <p>
                        <b>Ioana Mischie</b> (transmedia concept and direction) este cineastă de origine română
                        (scenaristă/regizoare), premiată pentru film, VR, creative writing și pentru concepte transmedia
                        inovatoare. În anul 2018 a fost bursieră Fulbright - cel mai prestigios program de schimburi
                        culturale, ştiinţifice şi educaţionale desfăşurat de Statele Unite ale Americii, în cadrul USC –
                        School of Cinematic Arts, din Los Angeles, California pentru a cerceta transmedia storytelling
                        (Mixed Reality Lab, Worldbuilding Lab, Civic Imagination Lab, JoVRnalism). Proiectele sale
                        artistice au călătorit la peste 70 de festivaluri de film și VR din întreaga lume (Palm Springs
                        ISFF, IFF Hamptons, IFF, Thessaloniki IFF, Beijing ISFVF, GoEast IFF) și la numeroase programe
                        internaționale (Berlinale Talents – Script Station, Sundance Workshop – Capalbio, Cannes
                        International Screenwriters Pavilion, Locarno Academy Meetings). A câștigat premii oferite de
                        The Webby Awards, The Steamer Salon, FEST. A colaborat cu succes în calitate de scenarist/
                        regizor cu Channel 4 în Marea Britanie (pentru două seriale de documentar online cu un număr
                        record de vizionări în UK). Dintre proiectele cinematografice recente, a scris și a regizat “237
                        de ani”, un scurt-metraj produs de ”Legende Films” – casă de producție de origine franceză
                        premiată cu Oscar. Cel mai recent scurt-metraj, „Cumulonimbus” a fost nominalizat la Premiile
                        Gopo pentru Cel mai bun scurt-metraj, fiind unul dintre scurt-metrajele finaliste selectate de
                        Gus Van Sant în cadrul competiției Coppola Shorts. Este co-fondator și președinte al
                        Storyscapes, asociație axată pe crearea de universuri transmedia. Imaginând lumea ca un loc de
                        joacă neo-creativ, ea crede că povestitorii sunt “arhitecți ai viitorului” (Buckminster Fuller).
                    </p>
                    <p>
                        <b>Zsolt Magyari </b> (director de imagine 3D) este operator și director de imagine. A studiat
                        în cadrul UNATC și în cadrul Universității de Artă Teatrală și Film din Budapesta. Incepând din
                        anul 2009 a lucrat în București, Budapesta, Berlin și Londra. A filmat videoclipuri, filme de
                        ficțiune și documentare (My Home Tarnabod, Adrenalin Turbulence, Pașaportul Portocaliu). A
                        cooperat în mai multe producții europene 3D (The Forbidden Girl, Lost Place, My Life Rehearsed
                        In One Leg, Love Me Fear Me) și a predat filmare stereoscopică în Berlin, Stuttgart și
                        Manchester.
                    </p>
                    <p>
                        <b>Radu Voinea</b> (director de imagine 2D) este director de imagine axat pe film (35mm, s16mm,
                        color B/W) și proiecte digitale. A absolvit UNATC și în timp a fost selectat la programe
                        internaționale precum Monfilm Fest 2016, Bucharest Romanian Society of Cinematographers
                        Masterclass 2010, 2011, 2012, 2013, 2014, 2015, Visegrad Film Forum 2012, Grundtvig Workshop
                        "Digital Storytelling - a creative approach to literacy teaching" Barcelona 2013, Sarajevo
                        Talent Campus 2014, Film Spring Open Air (with Polish Society of Cinematographers scholarship)
                        2014.
                    </p>
                    <p>
                        <b>Retro Future</b> (set design and costume design) este un studio creativ din București
                        specializat în design interior, art direction, costume design, fashion și consultanță vizuală.
                        Au semnat numeroase proiecte de film, publicitate, fashion și proiecte artistice personale. Anca
                        Miron și Sonia Constantinescu, creatoarele Retro Future, au o experiență de peste 10 ani în
                        design.{' '}
                    </p>
                    <p>
                        <b>Cristi Vieriu</b> (sound design and music) este creator audio-vizual, editor și sound
                        designer axat pe storytelling audio-vizual și implementare multimedia. A absolvit Montaj și
                        Sunet și are experiență e post-producție video și VR. A creat sound design pentru teatru, film
                        și publicitate. Wild Draft este un studio de post-producție și de creație muzicală pentru film
                        și branded content în cadrul căruia activează.
                    </p>
                    <p>
                        <b>Mihai Gheorghiu</b> (sound mix) A lucrat mai bine de 30 de ani în domeniul audio și peste de
                        20 de ani în domeniul jocurilor audio. A realizat design complet de sunet, mix de muzică și
                        master pentru mai mult de 20 de jocuri, printre care: Silent Hunter 3, 4 and 5, Blazing Angels 1
                        and 2, HAWX 1 and 2, Chess Master, Just Dance 4, Knights Arena. De asemenea, a lucrat la piese
                        audio pentru jocurile Assassin’s Creed Rogue și Assassin’s Creed Syndicate. Am mixat și
                        masterizat piese muzicale ale compozitorul Jason Graves pentru Silent Hunter 5 și ale
                        compozitorului Tom Salta pentru jocurile HAWX. Are cunoștinte muzicale profesioniste, cânt la
                        clape, chitară, chitară bas și, ocazional, percuție.{' '}
                    </p>
                    <p>
                        <b>Sorin Baican</b> (producător național) este co-fondator, producător și CEO al Studioset, unul
                        dintre cele mai mari studio-uri de producție și post-producție din București. A produs și a
                        co-produs proiecte comerciale premiate, un film de lungmetraj și numeroase scurt-metraje, care
                        au fost selectate la festivaluri naționale și internaționale. Cu un background în montaj de
                        film, a semnat de asemenea editarea filmului Eu când vreau să fluier, fluier regizat de Florin
                        Șerban premiat cu Ursul de Argint la Berlinale și documentarul produs de HBO The Shukar
                        Collective Project, regizat de Matei Mocanu). Printre proiectele recete ale Studioset se numără
                        Opinci, un proiect de animație finanțat de Centrul Național al Cinematografiei.{' '}
                    </p>
                    <p>
                        <b>Dan Mateescu</b> (producător național) este co-fondator al Studioset, unul dintre cele mai
                        mari studio-uri de producție și post-producție din București și producător de film și branded
                        content. A semnat producția a sute de reclame și numeroase filme selectate internațional. Are
                        background în montaj de film și publicitate
                    </p>
                    <p>
                        <b>Ioana Țurcan</b> (producător creativ) este un artist vizual interdisciplinar, absolvent al
                        cursurilor post-masterale ale Royal Institute of Art, Suedia, departamentul Critical Images:
                        Dignity and Representation, un curs efectuat sub îndrumarea colectivului anonim de film sirian
                        Abounaddara. Ea are, de asemenea, un masterat în producția de film și video la College of Visual
                        and Performing Arts în New York, Syracuse, unde a studiat ca bursier Fulbright și unde a avut
                        oportunitatea de a preda cursuri de producție, scenaristică și arte vizuale. A urmat cursurile
                        masterale ale Universității Babeș-Bolyai din Cluj, specializarea - Film documentar și de
                        asemenea, cursuri de licență în cadrul aceleași universități, secția: Imagine de Film și
                        Televiziune. Ioana Țurcan a colaborat și colaborează cu diverși artiști vizuali și regizori de
                        film, lucrand de asemenea în prezent ca producător de film la Studioset.
                    </p>
                    <p>
                        <b>Maria Lincu</b> (asistent de producție) și-a început activitatea în industria filmului în
                        2016, când s-a alăturat echipei Saga Film. Absolventă a secției de Scenaristică și studentă la
                        masterul de Producție de Film în cadrul UNATC, a lucrat de atunci pentru filme și reclame, atât
                        pentru proiecte de scurtmetraj, cât și de lungmetraj. In prezent lucreaza pentru casa de
                        productie Studioset, dezvoltand departamentul lor de film si manageriind proiecte in diferitele
                        lor stadii de evolutie.
                    </p>
                    <p>
                        <b>Ana-Maria Pîrvan</b> (coordonator distribuție) este la bază producător de film, a lucrat timp
                        de 10 ani pentru una dintre cele mai mari case de producție din România și în ultimii 5 ani ca
                        producător și producător executiv. Portofoliul ei reunește peste 100 de reclame pentru clienți
                        locali și internaționali, dar și filme realizate de Cristian Mungiu, Bogdan Apetri, Dorian
                        Boguță, Mihaela Popescu, care au fost prezentate la festivaluri de prestigiu (Cannes, Locarno,
                        Toronto, Cottbus). Absolventă a Facultății de Filosofie din cadrul Universității București, Ana
                        Maria a urmat cursuri internaționale precum Maia Workshop (Lituania), New Horizons Studio
                        (Polonia), Screen4AllCampus (Paris). În 2016, Ana Maria a creat Anchor Films.
                    </p>
                    <hr />
                    <p>
                        <b>Studioset (producător)</b> este una dintre cele mai mari companii de producție și
                        post-producție din România, cu o experiență de peste 11 ani, excelând creativ în publicitate și
                        mult mai recent dezvoltând contant film și VR.{' '}
                        <a href="https://studioset.tv/">(https://studioset.tv/)</a>
                    </p>
                    <p>
                        <b>Storyscapes (producător)</b> a fost inițiată în anul 2012 ca prima asociație axată pe
                        transmedia storytelling din România, cu un portofoliu în instalații transmedia/expanded cinema/
                        VR /literatura electronică. <a href="http://storyscap.es/"> (http://storyscap.es/)</a>
                    </p>
                    <p>
                        <b>Cinema Infinit (distribuitor)</b> este o companie recentă care facilitează inovarea creativă,
                        de producție și distribuție pentru francize transmedia (film/web/VR/AR/ARG).{' '}
                    </p>
                    <p>
                        <b>
                            Centrul Internațional de Cercetare și Educație în Tehnologii Inovativ Creative – CINETic
                            (partener academic)
                        </b>{' '}
                        a fost înființat în cadrul Universității Naționale de Artă Teatrală și Cinematografică ”I.L.
                        Caragiale” București, ca departament de cercetare. CINETic are ca misiune inovația tehnologică
                        și cercetarea în domeniile interacțiunii digitale și a neuroștiinței aplicate, în artele
                        performative. În cadrul CINETic sunt valorificate cunoștințele acumulate în domeniul teatrului,
                        filmului prin activitatea de cercetare și inovare, în proiecte interdisciplinare
                        artă-știință-tehnologie.
                    </p>
                </div>
            </Layout>
        );
    }
}

export default TeamPage;
